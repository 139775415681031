<template>
  <div class="tab-pane fade show active">
    <h6 class="text-uppercase">Installation progress</h6>
    <div class="progress">
      <div
        class="progress-bar bg-success"
        role="progressbar"
        style="width: 15%"
        aria-valuenow="15"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <p class="my-4">Please fill the fields below</p>
    <div class="alert alert-danger my-4" role="alert" v-if="error">
      {{ message }}
    </div>
    <form class="label-right">
      <div class="form-group row">
        <label for="dbuser" class="col-sm-3 col-form-label">Username</label>
        <div class="col-sm-9">
          <input type="text" v-model="form.username" class="form-control" id="dbuser" placeholder="Purchased by (username)" />
        </div>
      </div>
      <div class="form-group row">
        <label for="dbpass" class="col-sm-3 col-form-label">Licese Key</label>
        <div class="col-sm-9">
          <input type="text" v-model="form.code" class="form-control" id="dbpass" placeholder="Licese Key or Purchase Code" />
        </div>
      </div>
    </form>
    <div class="buttons">
      <button type="button" class="btn btn-outline-danger" @click="$router.push('/start')" :disabled="loading">
        Cancel
      </button>
      <button type="button" class="btn btn-outline-primary" @click="verify" :disabled="loading">
        <font-awesome-icon icon="spinner" fixed-width spin v-if="loading" /> Next
        <font-awesome-icon icon="long-arrow-alt-right" fixed-width v-if="!loading" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: false,
      loading: false,
      form: { username: '', code: '' },
      message: 'Unable to verify your license! please double check the form data before trying again.',
    };
  },
  mounted() {
    let installer = this.$storage.read('installer');
    if (installer && installer.step == '/check_list') {
      this.$storage.update('step', '/verify', 'installer');
      if (installer.verify) {
        this.form.code = installer.verify.code;
        this.form.username = installer.verify.username;
      }
    } else {
      this.$router.push('/start');
    }
  },
  watch: {
    form: {
      handler: function(form) {
        this.$storage.update('verify', form, 'installer');
      },
      deep: true,
    },
  },
  methods: {
    verify() {
      this.error = false;
      this.loading = true;
      if (this.form.username && this.form.code) {
        axios
          .post('/install/license', this.form)
          .then(({ data }) => {
            if (data.success) {
              this.$storage.update('installation_id', data.installation_id, 'installer');
              this.$router.push('/config');
            } else {
              this.notify(data.message);
              this.message = data.message;
            }
            this.loading = false;
          })
          .catch(err => {
            console.error(err.response);
            this.notify(err.response.data.error);
          });
      } else {
        this.message = 'Please fill the form (Username & Licence Key fields are required)';
        this.notify(this.message);
      }
    },
    notify(error) {
      this.$notify({
        type: 'error',
        duration: 10000,
        group: 'default',
        title: 'Error!',
        text: error,
      });
      this.error = true;
      this.loading = false;
    },
  },
};
</script>
