<template>
  <div class="tab-pane fade show active">
    <h6 class="text-uppercase">Installation progress</h6>
    <div class="progress">
      <div
        role="progressbar"
        style="width: 0%"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="100"
        class="progress-bar bg-warning"
      ></div>
    </div>
    <p class="mt-4 mb-3">
      Thank you for purchasing MPS (Modern Point of Sale Solution).
    </p>
    <p class="mb-3">
      Please follow the installation steps, you will need to prepare the following information to install the item.
    </p>
    <ol>
      <li class="mb-3">
        License details:
        <ul>
          <li>Purchased by (username)</li>
          <li>Licence Key / Purchase code</li>
        </ul>
      </li>
      <!-- <li class="mb-3">Server details:
                <ul>
                    <li>Domain Name</li>
                </ul>
            </li> -->
      <li class="mb-3">
        Database server details:
        <ul>
          <li>Database Hostname</li>
          <li>Database Name</li>
          <li>Database Username</li>
          <li>
            Database Password<br />
            <pre>Database must be empty & user must have all the privileges.</pre>
          </li>
        </ul>
      </li>
    </ol>
    <div class="buttons">
      <!-- <button type="button" class="btn btn-outline-danger">
                Cancel
            </button> -->
      <span></span>
      <button type="button" class="btn btn-outline-primary float-right" @click="$router.push('/license')">
        Next
        <font-awesome-icon icon="long-arrow-alt-right" fixed-width />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$storage.update('step', '/start', 'installer');
  },
};
</script>
