<template>
  <div class="tab-pane fade show active">
    <h6 class="text-uppercase">Installation progress</h6>
    <div class="progress">
      <div
        class="progress-bar bg-success"
        role="progressbar"
        style="width: 7%"
        aria-valuenow="7"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <h5 class="mt-4" :class="{ 'text-success': !error, 'text-danger': error }">{{ message }}</h5>
    <ul class="mb-2 pl-3 checklist" v-if="error">
      <li v-for="(item, index) in errors" :key="index"><font-awesome-icon icon="times" fixed-width class="text-danger" /> {{ item }}</li>
    </ul>
    <div class="buttons">
      <button type="button" class="btn btn-outline-danger" @click="$router.push('/start')" :disabled="loading">
        Cancel
      </button>
      <button type="button" class="btn btn-outline-primary" @click="getList" v-if="error">
        Check again
      </button>
      <button type="button" class="btn btn-outline-primary" @click="$router.push('/verify')" :disabled="loading" v-else>
        Next
        <font-awesome-icon icon="long-arrow-alt-right" fixed-width />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: false,
      errors: null,
      loading: true,
      message: '',
    };
  },
  mounted() {
    let installer = this.$storage.read('installer');
    if (installer && installer.step == '/license') {
      this.$storage.update('step', '/check_list', 'installer');
    } else {
      this.$router.push('/start');
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      axios
        .get('/install/checklist')
        .then(({ data }) => {
          if (data.success) {
            this.$router.push('/verify');
          } else {
            this.errors = data.errors;
            this.notify('Server Requirement! ' + data.message);
            this.message = data.message;
          }
          this.loading = false;
        })
        .catch(err => {
          console.error(err.response);
          this.notify('Installer is unable to call your server! Please make sure you are installing on proper web server.');
        });
    },
    notify(error) {
      this.$notify({
        type: 'error',
        duration: 10000,
        group: 'default',
        title: 'Error!',
        text: error,
      });
      this.error = true;
      this.loading = false;
    },
  },
};
</script>
